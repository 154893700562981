import React from 'react'
import Header from '../Header/Header';
import './Hero.css'
import hero_image from "../../assets/hero_image.png";
import hero_image_back from "../../assets/hero_image_back.png";
import Heart from "../../assets/heart.png";
import Calories from "../../assets/calories.png";
import {motion} from 'framer-motion';

const Hero = () => {
    
  const transition = {type: 'spring', duration : 3}
   const mobile = window.innerWidth<=768 ? true: false;

    return (  
     <div className="hero" id="home">
      <div className="blur hero-blur"></div>
        <div className='left-h'>
          <Header/>
           {/*...the best add...*/}
            <div className='the-best-ad'>
              <motion.div
                initial = {{left: mobile? '178px': '238px'}}
                whileInView = {{left: '8px'}}
                transition = {{...transition, type: 'tween'}}
                >
              </motion.div>
              <span>The best Fitness Club in town</span>
            </div>

           {/*...Hero Heading...*/}
          <div className='hero-text'>
            <div>
               <span className='stroke-text'>Shape </span>
               <span>Your</span>
            </div>
            <div>
              <span>Ideal Body</span>
            </div>
            <div>
              <span>
              At our gym we wil help you reach your mental goals and body goals.
              and live your dream.
              </span>
            </div>
          </div>
          
          {/*...Figures..*/}
           <div className='figures'>
            <div>
              <span>+50</span>
              <span>fitness programs</span>
            </div>
            <div>
              <span>+140</span>
              <span>Expert Coaches</span>
            </div>
            <div>
              <span>+278</span>
              <span>members joined</span>
            </div>
           </div>

          {/*...hero buttons..*/}
            <div className='hero-buttons'>'
              <button className='btn'>Get Started</button>
              <button className='btn'>Learn More</button>
            </div>
        </div>
      <div className='right-h'>
         <button className="btn">Join Now</button>

         <div className="heart-rate">
           <img src={Heart} alt="" />
           <span>Heart Rate</span><span>116 bpm</span>
         </div>

         {/*...hero buttons..*/}
         <img src={hero_image} alt="" className='hero-image'/>
         <img src={hero_image_back} alt="" className='hero-image-back'/>
         
         {/*...calories..*/}
          <div className='calories'>
            <img src={Calories} alt="" />
            <div>
              <span>Calories Burned</span>
              <span>220 kcal</span>
            </div>
          </div>
      </div>
     </div>        
     );
}
 
export default Hero;